// src/pages/OurPurpose/ImpactPartners.js

import React from 'react';
import ztloLogo from '../../assets/svg/impact-partners-icons/ztloLogo.svg'; // Update with the correct paths to your logo assets
import saYouthLogo from '../../assets/svg/impact-partners-icons/saYouthLogo.svg'
import yccLogo from '../../assets/svg/impact-partners-icons/yccLogo.svg'
import redYellowLogo from '../../assets/svg/impact-partners-icons/redYellowLogo.svg'
import rlabsLogo from '../../assets/svg/impact-partners-icons/rlabsLogo.svg'
import digifyLogo from '../../assets/svg/impact-partners-icons/digifyLogo.svg'
import './ImpactPartners.css';

const ImpactPartners = () => {
    return (
        <div className="impact-partners-container">
            <h2 className="impact-partners-heading">OUR IMPACT PARTNERS</h2>
            <div className="impact-partners-logos-wrapper">
                <div className="impact-partners-row">
                    <div className="impact-logo"><img src={ztloLogo} alt="Ztlo" /></div>
                    <div className="impact-logo"><img src={saYouthLogo} alt="SA Youth" /></div>
                </div>
                <div className="impact-partners-row">
                    <div className="impact-logo"><img src={yccLogo} alt="YCC" /></div>
                    <div className="impact-logo"><img src={redYellowLogo} alt="Red & Yellow" /></div>
                </div>
                <div className="impact-partners-row">
                    <div className="impact-logo"><img src={rlabsLogo} alt="RLabs" /></div>
                    <div className="impact-logo"><img src={digifyLogo} alt="Digify Africa" /></div>
                </div>
            </div>
        </div>

    );
};

export default ImpactPartners;