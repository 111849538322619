import React from "react";
import BrandsCarousel from "../../BrandsCarousel/BrandsCarousel";
import ContentSections from "../HeroContent/ContentSections";
import MissionStatement from "../MissionStatement/MissionStatement";
import InfoContainer from "../InfoContainer/InfoContainer";
import './Home.css';
import Hero from "../Hero/Hero";
import PurposeSection from "../../PurposeSection/PurposeSection";

const Home = () => {
  return (
    <div className="home-container">
      <div className="hero-home-container">
        <Hero />
      </div>
      <div className="brands-wrapper">
        <BrandsCarousel />
      </div>
      <div className="info-container-home-content">
        <InfoContainer />
      </div>
      <div className="brands-wrapper">
        <ContentSections />
      </div>
      <div className="mission-statement-wrapper">
        <MissionStatement />
      </div>
      <div className="purpose-wrapper">
        <PurposeSection />
      </div>
    </div>
  );
};


export default Home;
