import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import './Navbar.css';
import logo from '../../assets/images/edc-logo.png';
import { HiMenu, HiX } from 'react-icons/hi'; // Using react-icons for the hamburger icon

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Define the drop-in animation using react-spring
  const dropIn = useSpring({
    from: { transform: 'translate(-50%, -100%)', opacity: 0 },
    to: { transform: 'translate(-50%, 0%)', opacity: 1 },
    config: { tension: 150, friction: 30, duration: 1000 },
  });

  // Function to handle external navigation for the Login button
  const handleLoginClick = () => {
    window.location.href = 'https://app.edcsquared.io/login';
  };

  // Function to toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <animated.nav style={dropIn} className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="EDC SQUARED Logo" className="logo-image" />
          </Link>
        </div>
        <ul className="nav-links">
          <li><Link to="/for-creators" className="nav-button">For Creators</Link></li>
          <li><Link to="/for-brands" className="nav-button">For Brands</Link></li>
          <li><Link to="/our-purpose" className="nav-button">Our Purpose</Link></li>
          <li><Link to="/our-news" className="nav-button">Our News / Story</Link></li>
          <li><Link to="/lets-talk" className="nav-button">Let's Connect</Link></li>
        </ul>
        <button className="login-button" onClick={handleLoginClick}>
          Login / Sign Up
        </button>
      </animated.nav>

      {/* Mobile Navbar Background */}
      <div className="mobile-navbar-background" />

      {/* Mobile Navbar */}
      <div className="mobile-navbar">
        <div className="mobile-navbar-header">
          <Link to="/">
            <img src={logo} alt="EDC SQUARED Logo" className="logo-image" />
          </Link>
          <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <HiX size={30} /> : <HiMenu size={30} />}
          </button>
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-nav-links">
            <ul>
              <li><Link to="/for-creators" className="nav-button" onClick={toggleMobileMenu}>For Creators</Link></li>
              <li><Link to="/for-brands" className="nav-button" onClick={toggleMobileMenu}>For Brands</Link></li>
              <li><Link to="/our-purpose" className="nav-button" onClick={toggleMobileMenu}>Our Purpose</Link></li>
              <li><Link to="/our-news" className="nav-button" onClick={toggleMobileMenu}>Our News / Story</Link></li>
              <li><Link to="/lets-talk" className="nav-button" onClick={toggleMobileMenu}>Let's Connect</Link></li>
            </ul>
            <button className="login-button" onClick={handleLoginClick}>
              Login / Sign Up
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
