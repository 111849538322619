import React from 'react';
import InfoCard from '../../ForCreator/InfoCard/InfoCard';
import heartIcon from '../../../assets/svg/icon/edc-info-heart.svg';
import burstIcon from '../../../assets/svg/icon/edc-info-burst.svg';
import searchIcon from '../../../assets/svg/icon/edc-info-search.svg';
import lightIcon from '../../../assets/svg/icon/edc-info-light.svg';
import "./InfoContainer.css";

const InfoContainer = () => {
    const backgroundColor = 'transparent';
    const textColor = '#000';

    return (
        <div className="info-container-wrapper-home">
            <h2 className="info-container-heading-home">WHAT WE BELIEVE IN</h2>
            <div className="info-container-home">
                <InfoCard
                    image={heartIcon}
                    heading="AUTHENTICITY & RELATABILITY"
                    text="A content led approach to advertising utilizing relatable content to deliver strong consumer connections."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <InfoCard
                    image={burstIcon}
                    heading="DRIVING IMPACT TOGETHER"
                    text="We believe in creating social and economic impact by connecting brands with creators who inspire, innovate, and deliver measurable results."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <InfoCard
                    image={searchIcon}
                    heading="TRANSPARENCY & TRUST"
                    text="We’re committed to transparency and fostering an advertising industry that is fair, inclusive, and impactful for both brands and creators."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <InfoCard
                    image={lightIcon}
                    heading="EMPOWERING CREATIVITY"
                    text="Unlocking creativity at scale, enabling anyone with a mobile device to contribute authentic, impactful content."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
            </div>
        </div>
    );
};

export default InfoContainer;
