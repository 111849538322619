// src/components/InfoContainer/VerticalInfoContainer.js
import React from 'react';
import InfoCard from '../InfoCard/InfoCard';
import houseIcon from '../../../assets/svg/icon/edc-info-house.svg';
import handShakeIcon from '../../../assets/svg/icon/edc-info-handshake.svg';
import routeIcon from '../../../assets/svg/icon/edc-info-route.svg';
import lightIcon from '../../../assets/svg/icon/edc-info-light.svg';
import "./VerticalInfoContainer.css";

const VerticalInfoContainer = () => {
    const backgroundColor = 'transparent';
    const headingColor = '#FF872F';
    const headingSize = "46px"
    const cardWidth = "400px"

    return (
        <div className="vertical-info-container-wrapper-for-creator">
            <h2 className="vertical-info-container-heading-for-creator">THE EDC CREATOR EXPERIENCE</h2>
            <div className="vertical-info-container-for-creator">
                <InfoCard
                    image={lightIcon}
                    heading="CREATIVITY"
                    text="Turn your creativity into earnings with EDC. Whether you’re just starting out or a seasoned creator, anyone can submit their content and get rewarded based on its performance. Your unique ideas and authentic voice have real value, and we’re here to help you unlock it."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
                <InfoCard
                    image={houseIcon}
                    heading="COMMUNITY"
                    text="At EDC, community means support every step of the way. From content tips and tricks to insights on building and growing your personal brand, we’re here to help you thrive. Join a network of creators who uplift, inspire, and collaborate, because together, we achieve more."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
                <InfoCard
                    image={handShakeIcon}
                    heading="OPPORTUNITY"
                    text="Opportunity starts here. Gain access to some of the best brands and exciting activations, all designed to showcase your creativity. With just a few clicks, you can submit your content, connect with brands, and unlock the potential to grow your influence and earnings."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
                <InfoCard
                    image={routeIcon}
                    heading="JOURNEY"
                    text="The journey of content creation and brand collaboration is where authenticity comes to life. It’s more than just the final product—it’s about building meaningful connections, honing your craft, and growing alongside brands that value your unique perspective."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
            </div>
        </div>
    );
};

export default VerticalInfoContainer;
