import React from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import "./PurposeSection.css";
import purposeImage from "../../assets/svg/edc-home-our-purpose.svg"; // Replace with the actual path to your image

const PurposeSection = () => {
    const navigate = useNavigate();

    // Scroll animations
    const [contentRef, contentInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [imageRef, imageInView] = useInView({ triggerOnce: true, threshold: 0.1 });

    const contentAnimation = useSpring({
        opacity: contentInView ? 1 : 0,
        transform: contentInView ? "translateX(0px)" : "translateX(-50px)",
        config: { tension: 170, friction: 26 },
    });

    const imageAnimation = useSpring({
        opacity: imageInView ? 1 : 0,
        transform: imageInView ? "translateX(0px)" : "translateX(50px)",
        config: { tension: 170, friction: 26 },
    });

    const handleButtonClick = () => {
        navigate("/our-purpose");
    };

    return (
        <div className="purpose-section">
            {/* Content with animation */}
            <animated.div ref={contentRef} style={contentAnimation} className="purpose-content">
                <h2 className="purpose-heading">OUR PURPOSE</h2>
                <p className="purpose-description">
                    We're passionate about making a meaningful difference in the world by <span>empowering creators & brands.</span>
                </p>
                <ul className="purpose-list">
                    <li>Creating opportunities for anyone with a mobile device.</li>
                    <li>Enabling brands to market in a more ethical & honest way.</li>
                </ul>
                <button className="purpose-button" onClick={handleButtonClick}>
                    LEARN MORE
                </button>
            </animated.div>

            {/* Image with animation */}
            <animated.div ref={imageRef} style={imageAnimation} className="purpose-image-wrapper">
                <img src={purposeImage} alt="Purpose" className="purpose-image" />
            </animated.div>
        </div>
    );
};

export default PurposeSection;
