import React from "react";
import { useSpring, animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import "./ContentSections.css";
import for_brands from "../../../assets/images/edc-home-for-brands.png";
import for_creators from "../../../assets/images/edc-for-creator-home.png";
import { creatorOrange, brandGreen } from "../../../constants/colors.js";

const ContentSections = () => {
    return (
        <div className="content-sections">
            <Section
                title="FOR BRANDS"
                heading="UNLOCK THE AUTHENTICITY, DIVERSITY AND SCALE OF EVERYDAY CONTENT TO WIN ATTENTION AND INFLUENCE ACTION."
                description="In the age of short-form video, capturing consumer attention comes down to authenticity and relatability. Everyday Content from Everyday Creators delivers genuine, human-centered content-offering the diversity to resonate deeply and the scale to combat creative fatigue."
                buttonText="LEARN MORE"
                imageSrc={for_brands}
                headingColor={brandGreen}
                hoverColor={brandGreen}
                imagePosition="right"
                navigateTo="/for-brands"
            />
            <Section
                title="FOR CREATORS"
                heading="WANT TO BECOME AN EVERYDAY CONTENT CREATOR?"
                description="Create and submit everyday content around the brands you love and grow an always-on income."
                buttonText="LEARN MORE"
                imageSrc={for_creators}
                headingColor={creatorOrange}
                hoverColor={creatorOrange}
                imagePosition="left"
                navigateTo="/for-creators"
            />
        </div>
    );
};

const Section = ({ title, heading, description, buttonText, imageSrc, headingColor, hoverColor, imagePosition, navigateTo }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const bounceAnimation = useSpring({
        transform: inView ? "scale(1)" : "scale(0.8)",
        opacity: inView ? 1 : 0,
        config: { tension: 170, friction: 12, mass: 1 },
    });

    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const handleButtonClick = () => {
        navigate(navigateTo);
    };

    return (
        <div className="section-container">
            <animated.div ref={ref} style={bounceAnimation} className={`section ${imagePosition === "left" ? "image-left" : "image-right"}`}>
                {isMobile ? (
                    // Mobile layout - Image first, then content
                    <>
                        <div className="section-image-container">
                            <div className={`section-image-outline ${imagePosition === "left" ? "left-outline" : "right-outline"}`}>
                                <div className={`${imagePosition === "left" ? "section-image-left" : "section-image-right"}`}>
                                    <img src={imageSrc} alt={title} />
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <h3>{title}</h3>
                            <h2 style={{ color: headingColor }}>{heading}</h2>
                            <p>{description}</p>
                            <button
                                className="learn-more-button"
                                style={{
                                    "--hover-color": hoverColor,
                                }}
                                onClick={handleButtonClick}
                            >
                                {buttonText}
                            </button>
                        </div>
                    </>
                ) : (
                    // Desktop layout - Original order
                    <>
                        {imagePosition === "left" && (
                            <div className="section-image-container">
                                <div className="section-image-outline left-outline">
                                    <div className="section-image-left">
                                        <img src={imageSrc} alt={title} />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="section-content">
                            <h3>{title}</h3>
                            <h2 style={{ color: headingColor }}>{heading}</h2>
                            <p>{description}</p>
                            <button
                                className="learn-more-button"
                                style={{
                                    "--hover-color": hoverColor,
                                }}
                                onClick={handleButtonClick}
                            >
                                {buttonText}
                            </button>
                        </div>
                        {imagePosition === "right" && (
                            <div className="section-image-container">
                                <div className="section-image-outline right-outline">
                                    <div className="section-image-right">
                                        <img src={imageSrc} alt={title} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </animated.div>
        </div>
    );
};

export default ContentSections;
