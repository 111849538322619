import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import './OurPurpose.css';
import ImpactPartners from '../../components/OurPurpose/ImpactPartners'; // Import the new ImpactPartners component

const OurPurpose = () => {
    const navigate = useNavigate();

    // Define the bounceIn animation for the text content and button
    const bounceIn = useSpring({
        from: { opacity: 0, transform: 'translateY(-50px)' }, // Initial state
        to: { opacity: 1, transform: 'translateY(0px)' },     // Final state
        config: { tension: 150, friction: 25 },               // Slower settings
    });

    const handleConnectClick = () => {
        navigate('/lets-talk');
    };

    return (
        <div className="our-purpose-container">
            <animated.div style={bounceIn} className="text-content-container">
                <div className="text-content">
                    <h1 className="our-purpose-heading">
                        TO IMPACT LIVES THROUGH <span className="highlight">CONTENT</span>
                    </h1>
                    <p className="purpose-text">
                        To drive positive social impact by providing the opportunity for anyone with a mobile device to monetize their content.
                    </p>
                    <p className="purpose-text">
                        To enable a more socially and morally responsible way of advertising by driving more ethical investments for advertisers.
                    </p>
                    <div className="connect-button-container">
                        <button onClick={handleConnectClick} className="connect-button">
                            LET'S CONNECT
                        </button>
                    </div>
                </div>

                {/* Add the Impact Partners section wrapped in a container to align to the right */}
                <div className="impact-partners-wrapper">
                    <ImpactPartners />
                </div>
            </animated.div>
        </div>
    );
};

export default OurPurpose;