// src/pages/ForBrands/ForBrands.js
import React from 'react';
import BrandHero from '../../components/ForBrands/BrandHero/BrandHero';
import CarouselComponent from '../../components/BrandsCarousel/BrandsCarousel';
import ContentEquation from '../../components/ForBrands/ContentEquation/ContentEquation';
import CaseStudiesCarousel from '../../components/ForBrands/CaseStudiesCarousel/CaseStudiesCarousel';
import VerticalInfoContainer from '../../components/ForBrands/VerticalInfoContainer/VerticalInfoContainer';
import MessageBox from '../../components/ForBrands/MessageBox/MessageBox';
import PurposeSection from "../../components/PurposeSection/PurposeSection"

const ForBrands = () => {
    return (
        <div>
            <BrandHero />
            <div style={{
                backgroundColor: "#fff",
                boxShadow: "0 20px 20px -10px rgba(0, 0, 0, 0.2)",
                borderRadius: "0 0 50px 50px",
                paddingBottom: "30px",
                zIndex: 2
            }}>
                <CarouselComponent />
            </div>
            <VerticalInfoContainer />
            <ContentEquation />
            <div style={{
                backgroundColor: "#fff",
                boxShadow: "0 20px 20px -10px rgba(0, 0, 0, 0.2)",
                borderRadius: "0 0 50px 50px",
                marginBottom: "100px"
            }}>
                <CaseStudiesCarousel />
            </div>

            <MessageBox />
            <div className="purpose-wrapper">
                <PurposeSection />
            </div>
        </div >
    );
};

export default ForBrands;