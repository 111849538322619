import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './MissionStatement.css';
import { creatorOrange } from '../../../constants/colors';

const MissionStatement = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const bounceAnimation = useSpring({
        transform: inView ? 'scale(1)' : 'scale(0.8)',
        opacity: inView ? 1 : 0,
        config: { tension: 170, friction: 12, mass: 1 },
    });

    return (
        <animated.div ref={ref} style={bounceAnimation} className="container">
            We’re driven by a <span className="highlight" style={{ color: creatorOrange }}>passion for positive social impact</span>,
            unlocking the power of Everyday Content to create <span className="highlight" style={{ color: creatorOrange }}>opportunities </span>
            for individuals, communities, and brands worldwide. We believe there’s a better way for brands
            to connect—one that aligns with the values of <span className="highlight" style={{ color: creatorOrange }}>today’s audiences</span>.
            By empowering authentic, responsible marketing, we help brands build trust and reach audiences
            in <span className="highlight" style={{ color: creatorOrange }}>meaningful, impactful ways</span>.
        </animated.div>
    );
};

export default MissionStatement;