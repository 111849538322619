// src/components/InfoCard/InfoCard.js
import React from 'react';
import './InfoCard.css';
import { useSpring, animated } from 'react-spring'; // Import react-spring for animations
import { useInView } from 'react-intersection-observer'; // Import useInView for scroll detection

const InfoCard = ({ image, heading, text, backgroundColor = '#ffffff', textColor = '#000000', headingColor = '#000000', headingSize = "24px", infoCardWidth = "300px" }) => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger animation only once when in view
        threshold: 0.1, // Trigger when 10% of the component is visible
    });

    // Define animation for scale and opacity
    const animationProps = useSpring({
        transform: inView ? 'scale(1)' : 'scale(0.9)',
        opacity: inView ? 1 : 0,
        config: { tension: 170, friction: 20 },
    });

    return (
        <animated.div
            ref={ref}
            style={{ ...animationProps, backgroundColor, width: infoCardWidth }}
            className="info-card"
        >
            <img src={image} alt={heading} className="info-card-image" />
            <h2 className="info-card-heading" style={{ color: headingColor, fontSize: headingSize }}>{heading}</h2>
            <p className="info-card-text" style={{ color: textColor }}>{text}</p>
        </animated.div>
    );
};

export default InfoCard;