import React from "react";
import { useSpring, animated } from "@react-spring/web"; // For animations
import { useInView } from "react-intersection-observer"; // For triggering animations on scroll
import "./CreatorHero.css";
import heroImage from "../../../assets/images/edc-for-creator-hero.png"; // Updated path
import whatsApp from "../../../assets/svg/edc-whatsapp.svg"; // Updated path
import { WHATSAPP_COMMUNITY_URL } from "../../../constants/constants";

const CreatorHero = () => {
    // Intersection Observer Hooks for animations
    const [headingRef, headingInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [descriptionRef, descriptionInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [buttonRef, buttonInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const [imageRef, imageInView] = useInView({ triggerOnce: true, threshold: 0.1 });

    // Animations for elements
    const headingAnimation = useSpring({
        opacity: headingInView ? 1 : 0,
        transform: headingInView ? "translateY(0px)" : "translateY(-50px)",
    });

    const descriptionAnimation = useSpring({
        opacity: descriptionInView ? 1 : 0,
        transform: descriptionInView ? "translateY(0px)" : "translateY(50px)",
    });

    const buttonAnimation = useSpring({
        opacity: buttonInView ? 1 : 0,
        transform: buttonInView ? "translateY(0px)" : "translateY(50px)",
    });

    const imageAnimation = useSpring({
        opacity: imageInView ? 1 : 0,
        transform: imageInView ? "scale(1)" : "scale(0.8)",
    });

    const handleLoginClick = () => {
        window.location.href = "https://app.edcsquared.io/login";
    };

    const handleJoinCommunityClick = () => {
        window.open(WHATSAPP_COMMUNITY_URL, "_blank");
    };

    return (
        <div className="creator-hero">
            {/* Animated Heading */}
            <animated.div ref={headingRef} style={headingAnimation} className="creator-hero-heading">
                <h1>
                    UNLOCKING YOUR CREATIVITY WITH <br /> COMMUNITY AND OPPORTUNITY.
                </h1>
            </animated.div>

            {/* Animated Description */}
            <animated.p
                ref={descriptionRef}
                style={descriptionAnimation}
                className="creator-hero-description"
            >
                EDC squared provides the opportunity to work with your favorite brands and easily monetize your everyday content.
            </animated.p>

            {/* Animated Button */}
            <animated.div ref={buttonRef} style={buttonAnimation} className="creator-login-button-container">
                <button onClick={handleLoginClick} className="creator-login-button-home">
                    LOGIN / SIGN UP
                </button>
            </animated.div>

            {/* Animated Image */}
            <animated.div
                ref={imageRef}
                style={imageAnimation}
                className="creator-hero-image-wrapper"
            >
                <img
                    src={heroImage}
                    alt="Creative Hero"
                    className="creator-hero-image"
                    loading="lazy" // Enable browser-native lazy loading
                />
            </animated.div>

            {/* Animated WhatsApp Section */}
            <animated.div className="creator-hero-community">
                <p>
                    EDC squared also has a <span className="community-highlight">WhatsApp community</span> where we share tips and tricks on content creation, and new brand partnerships and opportunities.
                </p>
                <div className="community-button-container">
                    <button onClick={handleJoinCommunityClick} className="community-button">
                        <img
                            src={whatsApp}
                            alt="WhatsApp Icon"
                            className="community-button-image"
                        />
                        JOIN COMMUNITY
                    </button>
                </div>
            </animated.div>
        </div>
    );
};

export default CreatorHero;
