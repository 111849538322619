// src/components/InfoContainer/InfoContainer.js
import React from 'react';
import InfoCard from '../InfoCard/InfoCard';
import heartIcon from '../../../assets/svg/icon/edc-info-heart.svg';
import burstIcon from '../../../assets/svg/icon/edc-info-burst.svg';
import searchIcon from '../../../assets/svg/icon/edc-info-search.svg';
import lightIcon from '../../../assets/svg/icon/edc-info-light.svg';
import "./InfoContainer.css"

const InfoContainer = () => {

    const backgroundColor = 'transparent';
    const textColor = '#000';
    return (
        <div className="info-container-wrapper-for-creator">
            <h2 className="info-container-heading-for-creator">HOW IT WORKS</h2>
            <div className="info-container-for-creator">
                <InfoCard
                    image={heartIcon}
                    heading="Sign up"
                    text="Join the EDC community by creating your profile—it’s quick, simple, and opens the door to brand opportunities."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <InfoCard
                    image={burstIcon}
                    heading="Submit YOUR CONTENT"
                    text="Choose from exciting brand activations and submit your best work to get noticed."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <InfoCard
                    image={searchIcon}
                    heading="Approval Made Easy"
                    text="Your content will go through a quick review process to ensure it’s ready to shine."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
                <InfoCard
                    image={lightIcon}
                    heading="Earn Based on Brand Usage"
                    text="Start earning as brands use your approved content — your creativity, your rewards."
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                />
            </div>
        </div>
    );
};

export default InfoContainer;