import React, { useState } from 'react';
import Slider from 'react-slick';
import './CaseStudiesCarousel.css';
import pdfImage1 from '../../../assets/pdfImages/EDC_case_study_01.jpg';
import pdfImage2 from '../../../assets/pdfImages/Capitec_case_study_02.jpg';
import pdfImage3 from '../../../assets/pdfImages/NikNaks_case_study_03.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSpring, animated } from 'react-spring'; // Import react-spring for animations
import { useInView } from 'react-intersection-observer'; // Import useInView for scroll detection

const CaseStudiesCarousel = () => {
    const [activeSlide, setActiveSlide] = useState(0); // State to keep track of the active slide

    // Scroll trigger setup
    const { ref, inView } = useInView({
        triggerOnce: true, // Animation triggers only once
        threshold: 0.1, // Trigger animation when 10% of the component is visible
    });

    // Animation configuration for the carousel
    const bounceAnimation = useSpring({
        transform: inView ? 'scale(1)' : 'scale(0.8)',
        opacity: inView ? 1 : 0,
        config: { tension: 170, friction: 12, mass: 1 },
    });

    // Settings for the slider
    // Settings for the slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        centerPadding: '200px',
        afterChange: (current) => setActiveSlide(current),
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false, // Disable centering for mobile
                    centerPadding: '0px', // Remove extra padding for smaller screens
                    arrows: false, // Remove arrows for a cleaner mobile experience
                }
            }
        ],
    };


    return (
        <animated.div ref={ref} style={bounceAnimation} className="carousel-container-main">
            <h2 className="carousel-heading-main">Case Studies</h2>
            <Slider {...settings}>
                <div className={`carousel-item-main ${activeSlide === 0 ? 'active-slide' : ''}`}>
                    <img
                        src={pdfImage1}
                        alt="Case Study 1"
                        className="carousel-image-main"
                    />
                </div>
                <div className={`carousel-item-main ${activeSlide === 1 ? 'active-slide' : ''}`}>
                    <img
                        src={pdfImage2}
                        alt="Case Study 2"
                        className="carousel-image-main"
                    />
                </div>
                <div className={`carousel-item-main ${activeSlide === 2 ? 'active-slide' : ''}`}>
                    <img
                        src={pdfImage3}
                        alt="Case Study 3"
                        className="carousel-image-main"
                    />
                </div>
            </Slider>
        </animated.div>
    );
};

export default CaseStudiesCarousel;
