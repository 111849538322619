import React, { useEffect } from "react";
import { motion, useMotionValue, animate } from "framer-motion";
import "./BrandsCarousel.css";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
// Dynamically import all images from the assets/images folder
const importAllImages = (requireContext) =>
  requireContext.keys().map(requireContext);

const images = importAllImages(
  require.context("../../assets/images/brand-carousel", false, /\.(png|jpe?g|svg)$/)
);

const CarouselComponent = () => {
  const xTranslation = useMotionValue(0);
  const navigate = useNavigate();

  const handleConnectClick = () => {
    navigate('/lets-talk');
  };

  useEffect(() => {
    const imageWidth = 75;
    const imagePadding = 20;
    const totalWidthPerImage = imageWidth + imagePadding;

    const totalWidth = totalWidthPerImage * images.length * 2;
    const scrollSpeed = 100; // Speed in pixels per second
    const duration = totalWidth / scrollSpeed;
    const finalPosition = -totalWidthPerImage * images.length;

    const controls = animate(xTranslation, [0, finalPosition], {
      ease: "linear",
      duration: duration,
      repeat: Infinity,
      repeatType: "loop",
    });

    return () => controls.stop();
  }, [xTranslation]);

  return (
    <motion.div
      className="brands-carousel-section"
      initial={{ opacity: 0, y: -50 }} // Load-in animation start
      animate={{ opacity: 1, y: 0 }}  // Load-in animation end
      transition={{
        duration: 0.8,
        ease: "easeOut",
      }}
    >
      {/* Add the heading */}
      <motion.h2
        className="brands-heading"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.6,
          delay: 0.2, // Delay for staggered animation
          ease: "easeOut",
        }}
      >
        BRANDS WE WORK WITH
      </motion.h2>
      <div className="carousel-container">
        <motion.div
          className="carousel-track"
          style={{ x: xTranslation }}
        >
          {[...images, ...images].map((image, index) => (
            <div key={index} className="carousel-item">
              <img alt={`Brand ${index + 1}`} src={image} className="logo-image" />
            </div>
          ))}
        </motion.div>
      </div>
      {/* Add the connect button below the carousel */}
      <div className="connect-button-container-home">
        <button onClick={handleConnectClick} className="connect-button-home">
          LET'S CONNECT
        </button>
      </div>
    </motion.div>
  );
};

export default CarouselComponent;
