import React from 'react';
import InfoCard from '../../ForCreator/InfoCard/InfoCard';
import gearIcon from '../../../assets/svg/icon/edc-info-setting-black.svg';
import trendingUpIcon from '../../../assets/svg/icon/edc-info-trending-up.svg';
import authenticityIcon from "../../../assets/svg/icon/edc-info-trust.svg"
import divercityIcon from "../../../assets/svg/icon/edc-info-divercity.svg"
import './VerticalInfoContainer.css';

const VerticalInfoContainer = () => {
    const backgroundColor = 'transparent';
    const headingColor = '#00B1B5';
    const headingSize = "46px";
    const cardWidth = "400px";

    return (
        <div className="vertical-info-container-wrapper-for-brand">
            <div className="vertical-info-container-heading-wrapper-for-brand">
                <h2 className="vertical-info-container-heading-for-brand">
                    THE KEY TO WINNING ON TIKTOK, REELS, AND SHORTS
                </h2>
                <p className="vertical-info-container-description-for-brand">
                    Everyday Content is built on four key pillars that empower brands to thrive in the short-form video era.
                    Authenticity, Scale, Diversity, and Control work together to create content that connects, performs, and
                    drives measurable impact.
                </p>
            </div>
            <div className="vertical-info-container-for-brand">
                <InfoCard
                    image={authenticityIcon}
                    heading="AUTHENTICITY"
                    text="Consumers crave authentic, relatable experiences with brands. Everyday Content creates genuine connections that drive trust, engagement, and measurable impact."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
                <InfoCard
                    image={trendingUpIcon}
                    heading="SCALE"
                    text="Creative fatigue is a major challenge. Everyday Content delivers a constant flow of fresh, high-performing content at scale, maximizing ad investment efficiency and impact."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
                <InfoCard
                    image={divercityIcon}
                    heading="DIVERSITY"
                    text="Content graph algorithms thrive on a diversity of content to deliver relatability across audiences and interests. Everyday Content provides a broad range of authentic content, creating meaningful connections with micro-audiences."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
                <InfoCard
                    image={gearIcon}
                    heading="Control"
                    text="EDC offer an innovative solution meaning brands stay in control by investing in what truly matters—the content itself and the measurable impact it delivers for your brand."
                    backgroundColor={backgroundColor}
                    headingColor={headingColor}
                    headingSize={headingSize}
                    infoCardWidth={cardWidth}
                />
            </div>
        </div>
    );
};

export default VerticalInfoContainer;