// src/pages/ForCreators/ForCreators.js
import React from 'react';
import CreatorHero from '../../components/ForCreator/CreatorHero/CreatorHero';
import InfoContainer from '../../components/ForCreator/InfoContainer/InfoContainer';
import VerticalInfoContainer from "../../components/ForCreator/VerticalInfoContainer/VerticalInfoContainer"
import MessageBox from '../../components/ForCreator/MessageBox/MessageBox';
import PurposeSection from '../../components/PurposeSection/PurposeSection';
const ForCreators = () => {
    const handleLoginClick = () => {
        window.location.href = 'https://app.edcsquared.io/login';
    };

    return (
        <>
            <CreatorHero
                onButtonClick={handleLoginClick}
            />
            <div style={{ width: '100%' }}> {/* Ensure full-width container */}
                <InfoContainer />
            </div>
            <VerticalInfoContainer />
            <div style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                <MessageBox />
            </div>
            <div className="purpose-wrapper">
                <PurposeSection />
            </div>
        </>
    );
};

export default ForCreators;